import { Button, Menu } from "antd";
import { Header } from "antd/lib/layout/layout";
import { AiOutlineMenuFold, AiOutlineMenuUnfold } from "react-icons/ai";

const Component = ({ collapsed, setCollapsed }) => {
  const width = 100;
  return (
    <Header>
      <div className="logo" style={{ width }}>
        <a href="/ipassid" className="hover:!text-white">
          iPass ID
          {/* <img alt="olecons" src="https://olecons.com/images/logo2.png" style={{ height: "30px", filter: "grayscale(1) brightness(2)" }} /> */}
        </a>
      </div>
      <div id="pageTitle">CONNECTIONS</div>
      <div
        className="toggler"
        style={{
          width,
          textAlign: "right",
          display: "flex",
          flexDirection: "row-reverse",
        }}
      >
        <a
          className="trigger hover:!text-white "
          onClick={() => {
            setCollapsed(!collapsed);
          }}
        >
          {collapsed ? <AiOutlineMenuFold /> : <AiOutlineMenuUnfold />}
        </a>
      </div>
    </Header>
  );
};

export default Component;
