import React, { useState, useEffect } from "react";
import "./CookieConsent.css";
import { baseUrl } from "../../services/services.profile";

const CookieConsent = () => {
  const [isAccepted, setIsAccepted] = useState(true);

  const handleAccept = () => {
    setIsAccepted(true);
    // Save the user's consent in localStorage or cookies
    localStorage.setItem("cookieConsent", "true");
  };

  // Check if the user has already accepted cookies
  const checkConsent = () => {
    const consent = localStorage.getItem("cookieConsent");
    if (consent && consent !== "") {
      setIsAccepted(true);
    } else {
      setIsAccepted(false);
    }
  };

  useEffect(() => {
    checkConsent();
  }, []);

  return (
    <>
      {!isAccepted && (
        <div className="cookie-consent">
          <div className="cookie-message">
            <div>
              We use cookies to offer you a better browsing experience and
              personalise content and to analyse our traffic. Read about how we
              use cookies and how you can control them by clicking{" "}
              <a
                href={`${baseUrl + "/cookie_policy.html"}`}
                rel="noreferrer"
                target="_blank"
                className="!font-bold !underline"
              >
                Cookie Settings
              </a>
              . You consent to our cookies if you continue to use this website.
            </div>
            <div className="cookie-buttons">
              <button onClick={handleAccept}>Got it!</button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CookieConsent;
