import { baseUrl, getProfile } from "../services/services.profile";
import React from "react";
import { useState } from "react";
import axios from "axios";
import { useEffect } from "react";
import NotFoundData from "../components/NotFoundData";

const ConnectionsRequests = () => {
  const { message } = require("antd");
  const [profile, setProfile] = useState({});
  const [requests, setRequests] = useState([]);

  const getRequests = () => {
    axios.get(baseUrl + "/api/connect/requests").then((res) => {
      setRequests(res.data.data);
    });
  };

  const viewArtpassID = (user) => {
    window.open(baseUrl + "/connectreport/" + user._id, "_blank");
  };

  const acceptRequest = (request) => {
    axios
      .post(baseUrl + "/api/connect/request/" + request.req_id + "/accept")
      .then((res) => {
        message.info("Request Accepted Successfully");
        getRequests();
        setTimeout(() => {
          window.location.href = "/ipassid/connections";
        }, 500);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const cancelRequest = (request) => {
    axios
      .post(baseUrl + "/api/connect/request/" + request.req_id + "/cancel")
      .then((res) => {
        message.info("Request Cancelled Successfully");
        getRequests();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const declineRequest = (request) => {
    axios
      .post(baseUrl + "/api/connect/request/" + request.req_id + "/decline")
      .then((res) => {
        message.info("Request Declined Successfully");
        getRequests();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getProfile()
      .then((res) => {
        setProfile(res);
        getRequests();
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <>
      {requests.length === 0 ? (
        <>
          <NotFoundData
            message={
              <>
                Requests to make connections with you will appear here <br /> to
                access your iPass ID and CDD Report
                {/* <button
                            className="bg-primary  px-3 h-9 w-full text-white  rounded-lg"
                         
                          >
                            Accept
                          </button>
                          <button
                            className="border border-[#86c1f4]  text-primary w-full whitespace-nowrap  px-3 h-9  rounded-lg"
                          
                          >
                            artpass ID
                          </button>
                          <button className="bg-gray-300 hover:cursor-not-allowed w-full text-primary px-2 h-9 rounded-lg">
                            Pending
                          </button>
                          <button
                          className="px-2 h-7 border border-danger rounded-[4px] text-[14px] text-danger w-full md:w-auto hover:text-white hover:bg-danger"
                       
                        >
                          Decline
                        </button> */}
              </>
            }
          />
        </>
      ) : (
        <>
          {(requests || []).map((request, i) => {
            const connector =
              profile._id == request?.sender._id
                ? request?.receiver
                : request?.sender;
            return (
              <div
                className="sm:flex w-full gap-4 justify-between  mb-6"
                key={i}
              >
                <div className="2xl:w-[12%] xl:w-[15%] md:w-[22%] sm:w-[25%] w-full flex justify-center items-center xl:gap-4 gap-2 mb-4 md:mb-0">
                  <div className="h-32 w-32">
                    <img
                      className="h-full w-full object-cover rounded-full"
                      src={baseUrl + "/" + connector?.avatar}
                      alt=""
                    />
                  </div>
                </div>
                <div className="2xl:w-[88%] xl:w-[85%] md:w-[78%] sm:w-[75%] w-full bg-[#f0f6fd] rounded-md p-3 md:flex justify-between gap-4">
                  <div className="text-[14px] flex flex-col justify-center items-start px-2 mb-3">
                    {/* connector company name  */}
                    {connector?.company && <p>{connector?.company?.name}</p>}

                    {/* connector full name  */}
                    <p className="font-bold ">
                      {connector?.fname + " " + connector?.lname}
                    </p>

                    {/* connector username  */}
                    <p> @{connector?.username}</p>

                    {/* Connection requested on date & time  */}
                    <div className="flex gap-1 mb-3 mt-2">
                      <p>Connection requested on: </p>
                      <span className="font-semibold">
                        {new Date(request?.req_time).toDateString()}
                      </span>
                    </div>

                    <div className="flex w-full gap-2">
                      {/* Cancel button  */}
                      {profile._id == request.sender._id && (
                        <button
                          className="px-2 h-7 border border-danger rounded-[4px] text-[14px] text-danger  w-full md:w-auto  hover:text-white hover:bg-danger"
                          onClick={() => {
                            cancelRequest(request);
                          }}
                        >
                          Cancel
                        </button>
                      )}

                      {/* Decline button  */}
                      {profile._id == request.receiver._id && (
                        <button
                          className="px-2 h-7 border border-danger rounded-[4px] text-[14px] text-danger w-full md:w-auto hover:text-white hover:bg-danger"
                          onClick={() => {
                            declineRequest(request);
                          }}
                        >
                          Decline
                        </button>
                      )}
                    </div>
                  </div>

                  <div className="flex flex-col justify-center text-[15px] items-center gap-[10px] px-2">
                    {profile._id == request.sender._id &&
                      request.status == "pending" && (
                        <>
                          <button className="bg-gray-300 hover:cursor-not-allowed w-full text-primary px-2 h-9 rounded-lg">
                            Pending
                          </button>
                          <button
                            className="border border-[#86c1f4]  text-primary w-full whitespace-nowrap  px-3 h-9  rounded-lg"
                            onClick={() => {
                              viewArtpassID(connector);
                            }}
                          >
                            artpass ID
                          </button>
                        </>
                      )}

                    {profile._id == request.receiver._id &&
                      request.status == "pending" && (
                        <>
                          <button
                            className="bg-primary  px-3 h-9 w-full text-white  rounded-lg"
                            onClick={() => {
                              acceptRequest(request);
                            }}
                          >
                            Accept
                          </button>
                          <button
                            className="border border-[#86c1f4]  text-primary w-full whitespace-nowrap  px-3 h-9  rounded-lg"
                            onClick={() => {
                              viewArtpassID(connector);
                            }}
                          >
                            artpass ID
                          </button>
                        </>
                      )}
                  </div>
                </div>
              </div>
            );
          })}
        </>
      )}
    </>
  );
};

export default ConnectionsRequests;
