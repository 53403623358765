import { useEffect } from "react";
import { clearLocalStorage } from "../services/local-storage";

function Logout() {
  useEffect(() => {
    // Removes all the keys from local storage
    clearLocalStorage();
    localStorage.removeItem("userId");
    window.location.href = "/ipassid/";
  }, []);

  return;
}

export default Logout;
