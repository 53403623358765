/* eslint-disable react/jsx-no-target-blank */
import { Formik } from "formik";
import React from "react";
import { useState } from "react";
import logoImg from "../assets/images/ipass_popup.png";
import ForgotPassword from "./ForgotPassword";

const Infor = ({ setModal, modal, isClose }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [user, setUser] = useState(null);

  return (
    <>
      <ForgotPassword
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        setModal={setModal}
      />
      {modal ? (
        <>
          <Formik>
            {() => (
              <div className="fixed inset-0 z-10 overflow-y-auto">
                <div
                  className="fixed inset-0 w-full h-full bg-black opacity-40"
                  onClick={() => setModal(false)}
                ></div>
                <div className="flex flex-col justify-center items-center min-h-screen bg-[#00000073] py-8">
                  <div className="relative w-full max-w-[50rem] mx-auto bg-white rounded-md shadow-lg">
                    <div className="flex justify-center items-center rounded-t-md text-white font-bold p-6">
                      <img
                        src={logoImg}
                        alt=""
                        style={{ width: "60%", marginTop: "30px" }}
                        className="  "
                      />
                    </div>
                    <div
                      className="w-full d-flex flex-column px-8 pt-4 pb-10 "
                      style={{ textAlign: "start" }}
                    >
                      <h4>
                        <strong>
                          Welcome to iPass - Your Trusted Partner in Art and
                          Luxury AML Compliance
                        </strong>
                      </h4>
                      <p>
                        As you may have noticed, you've been redirected here
                        from our former artpass ID site. We're thrilled to share
                        an exciting evolution in our journey towards simplifying
                        Anti-Money Laundering (AML) compliance for the art and
                        luxury markets. artpass ID has transformed into iPass ID
                        and iPass Pro, marking a new chapter in our mission to
                        foster a culture of trust and transparency across a
                        wider spectrum of high-value transactions.{" "}
                      </p>

                      <h5>
                        <strong>What's New? </strong>
                      </h5>
                      <p>
                        iPass ID and iPass Pro are our latest offerings designed
                        to cater to the unique needs of our diverse clientele,
                        extending our services beyond the art market to embrace
                        the luxury sector. This strategic shift allows us to
                        support a broader range of transactions, ensuring
                        compliance with the most recent AML regulations,
                        including AMLD6, across both domains.{" "}
                      </p>

                      <h5>
                        <strong>
                          For Our Obliged Entities:{" "}
                          <a href="pro.ipass.id">
                            <strong style={{ color: "#467886" }}>
                              iPass Pro
                            </strong>
                          </a>{" "}
                        </strong>
                      </h5>

                      <p>
                        iPass Pro is our bespoke platform for Art Market
                        Participants (AMPs) and Luxury Market Participants
                        (LMPs). It serves as a comprehensive tool for managing
                        your AML obligations efficiently. With iPass Pro, you'll
                        have access to a suite of advanced verification tools,
                        real-time updates, and a robust security framework to
                        streamline the due diligence process, ensuring you're
                        always audit-ready.
                      </p>
                      <h5>
                        <strong>
                          For Our End-Users:{" "}
                          <a href="app.ipass.id">
                            <strong style={{ color: "#467886" }}>
                              iPass ID
                            </strong>
                          </a>{" "}
                        </strong>
                      </h5>

                      <p>
                        iPass ID is tailored for buyers in the art and luxury
                        markets, offering a secure and straightforward way to
                        manage your digital identity. It's the perfect tool for
                        conducting transactions with ease, backed by a reusable
                        KYC digital identity system. With iPass ID, every
                        purchase is seamless, secure, and AML-compliant, thanks
                        to our digital identity trust network.{" "}
                      </p>

                      <h5>
                        <strong>Moving Forward Together </strong>
                      </h5>
                      <p>
                        Our transition from artpass ID to iPass ID and iPass Pro
                        signifies our commitment to expanding our reach and
                        enhancing our services. We're not just facilitating AML
                        compliant transactions; we're building a safer, more
                        transparent ecosystem for everyone involved in the art
                        and luxury sectors. Thank you for joining us on this
                        journey. We're excited to continue providing you with
                        the highest standards of service and security in AML
                        compliance.
                      </p>
                      <h5>
                        <strong>
                          Please do not hesitate to contact us for remarks or
                          questions at:{" "}
                          <a href="mailto:hello@ipass.id">
                            <strong style={{ color: "#467886" }}>
                              hello@ipass.id{" "}
                            </strong>
                          </a>
                        </strong>
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </Formik>
        </>
      ) : (
        ""
      )}
    </>
  );
};

export default Infor;
