const shouldNotBeRemovedKeys = ["cookieConsent"];

export const clearLocalStorage = () => {
  const removableKeys = [];
  for (let i = 0; i < localStorage.length; i++) {
    const key = localStorage.key(i);
    // Remove the item if it's not in the one we want to keep array
    if (!shouldNotBeRemovedKeys.includes(key)) {
      removableKeys.push(key);
    }
  }

  removableKeys.forEach((key) => {
    localStorage.removeItem(key);
  });
};
